/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "~@ng-select/ng-select/themes/material.theme.css";

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import '~quill/dist/quill.snow.css';

@import 'ngx-toastr/toastr';

@import "~jsoneditor/dist/jsoneditor.min.css";

body.light .mat-drawer-container,
body .light .mat-drawer-container {
    background-color: unset !important;
}

.button-dense {
    border-radius: 6px !important;
    max-height: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    font-weight: 300;
}

.mat-button-wrapper,
.mat-tab-header-pagination {
    z-index: 1 !important;
}

.mat-grid-list:nth-child(odd){
    background-color: #efefef;
}
.mat-button-small{
    height: 30px;
    max-height: 30px;
}

@keyframes mat-loading {
    to {transform: rotate(360deg);}
}

.mat-loading:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: mat-loading .8s linear infinite;
}


.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.xs .mat-form-field-wrapper .mat-form-field-flex{
    min-height: 35px!important;

}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.xs .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element{
    padding: 0;
}

.mat-sidenav-container{
    background-color: unset!important;
}
div.inline-diff{
    border:unset !important;
}

div.inline-diff-content-wrapper{
    width: initial!important;
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-has-label .mat-form-field-wrapper{
    margin-top: 20px!important;
}

